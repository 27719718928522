.landing-page {
  .landing-info {
    height: 40vh;
    background-color: grey;
    padding: 20px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    justify-content: center;
    .heading-text {
      background-color: black;
      color: white;
      text-align: center;
    }
  }
  .eamcet-landing {
    display: flex;
    justify-content: space-around;
    > :nth-child(1n) {
      width: 40%;
    }
    .landing-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  .college-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    > a {
      margin-bottom: 1rem;
    }
  }
}
@media (max-width: 720px) {
  .landing-page {
    .eamcet-landing {
      flex-direction: column;
      > :nth-child(1n) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

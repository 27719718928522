.eamcet-main {
  margin-top: 50px;
  padding-top: 1rem;
  z-index: 2;
  .heading {
    font-size: 2rem;
    font-weight: bold;
    z-index: 10;
    position: relative;
    text-decoration: underline;
    text-decoration-color: yellow;
  }
  .search {
    margin-top: 1.5rem;
    // display: flex;
    // justify-content: center;
    .search-inline {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      > :nth-child(1n) {
        margin-right: 1rem;
      }
    }
    .search-btn {
      margin: 0 0 1rem 0;
    }
    .input-error {
      color: red;
    }
  }

  .result {
    margin-top: 1.5rem;
    .result-header {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem;
    }
    .no-data {
      min-height: 200px;
    }
    .result-info {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      //   text-align: center;
    }
    .result-info-mobile {
      display: none;
    }
    .result-info-code {
      width: 10%;
    }
    .result-info-name {
      width: 30%;
    }
    .result-info-place {
      width: 20%;
    }
    .result-info-fee {
      width: 10%;
    }
    .result-info-rank {
      width: 10%;
    }
  }
}

@media (max-width: 720px) {
  .eamcet-main {
    .heading {
      font-size: 1.5rem;
      font-weight: bold;
      z-index: 10;
      position: relative;
      text-decoration: underline;
      text-decoration-color: yellow;
    }
    .search {
      margin-top: 1rem;
      .search-inline {
        flex-direction: column;
        margin-bottom: 0rem;
        > :nth-child(1n) {
          width: 100%;
          margin: 1rem 0 0 0;
        }
      }
      .search-form {
        .search-btn {
          margin: 1rem 0px;
        }
      }
    }
    .result {
      .hide {
        display: none;
      }
      .result-info-code {
        width: 20%;
      }
      .result-info-name {
        width: 60%;
      }
      .result-info-mobile {
        .wrapper-place-fee {
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
        }
        display: flex;
        padding: 0.5rem;
        justify-content: space-between;
        > :nth-child(1n) {
          // width: 50%;
          .result-header-inner {
            //font-size: 0.7rem;
            font-weight: bold;
            margin-right: 10px;
            font-style: italic;
          }
          > :first-child {
            margin-bottom: 5px;
          }
        }
      }
      .result-info-rank {
        width: 20%;
      }
    }
  }
  .menu-item-dropdown {
    word-break: break-word;
    white-space: break-spaces !important;
  }
}

.nav-bar {
  background-color: black;
  color: white;
  position: fixed;
  width: 100%;
  top: 0px;
  min-height: 50px;
  z-index: 100;
  // padding: 0px 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ul {
    list-style: none;
    display: flex;
    height: 100%;
    margin: 0px !important;
    padding: 0px;
  }
  &-right {
    li {
      width: 20%;
    }
  }
  &-left {
    .logo {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}

.App {
  min-height: calc(100vh - 70px);
  .app-layout {
    // max-width: 950px;
    margin: 50px auto 0px;
  }
  .top-banner {
    color: black;
    margin: 1rem 0;
    position: relative;
    .heading {
      font-size: 2rem;
      font-weight: bold;
      z-index: 10;
      position: relative;
      text-decoration: underline;
      text-decoration-color: yellow;
      // text-decoration-style: wavy;
    }
    .heading-underline {
      position: absolute;
      width: 100%;
      height: 0.4rem;
      background-color: yellow;
      top: 50%;
    }
  }
  > .MuiCard-root {
    margin-top: 1rem;
  }
  #frame {
    width: 100%;
    height: 600px;
    resize: both;
    background: white;
    max-width: 658px;
    width: calc(100% - 2px);
    border-radius: 3px;
    border: 1px solid rgb(219, 219, 219);
    box-shadow: none;
    display: block;
    margin: 0px 0px 12px;
    min-width: 326px;
    padding: 0px;
    .Header {
      display: none !important;
    }
  }

  .iframe-ex {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
  }
}

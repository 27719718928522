.college-ranking {
  .heading {
    font-size: 2rem;
    font-weight: bold;
    z-index: 10;
    position: relative;
    text-decoration: underline;
    text-decoration-color: yellow;
    margin: 1rem 0;
  }
  .body {
    ul {
      list-style: none;
    }
  }
}
